<template>
  <div class="input-group has-validation mb-3">
    <div class="form-floating form-floating-group flex-grow-1">
      <input
        :id="name"
        :name="name"
        :value="modelValue"
        :type="viewPassword ? 'text' : 'password'"
        :class="passwordClasses"
        placeholder="Password"
        required
        @input="changePassword($event.target.value)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
      <label v-if="label" :class="{}" :for="name">{{ label }}</label>
    </div>
    <button
      :id="`${name}-toggle`"
      type="button"
      class="input-group-text"
      @click="viewPasswordChange"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <i v-if="!viewPassword" class="fas fa-eye" aria-hidden="true"></i>
      <i v-if="viewPassword" class="fas fa-eye-slash" aria-hidden="true"></i>
    </button>
  </div>
  <div
    v-if="wasValidated && !modelValue"
    class="invalid-feedback d-block"
    style="margin-top: -0.8rem"
  >
    <slot name="invalid-feedback-message"></slot>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    wasValidated: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'changes-made', 'focus', 'blur'],
  setup(props, context) {
    // dynamic classes
    const passwordClasses = computed(() => {
      return { 'form-control': true, 'is-invalid': props.isInvalid };
    });

    // toggle password visibility
    const viewPassword = ref(false);
    const viewPasswordChange = () => {
      viewPassword.value = !viewPassword.value;
    };

    // update password user input
    const changePassword = (txt) => {
      context.emit('update:modelValue', txt);
      context.emit('changes-made');
    };

    return {
      passwordClasses,
      viewPassword,
      viewPasswordChange,
      changePassword,
    };
  },
};
</script>

<style lang="scss" scoped>
.input-group.is-invalid {
  ~ .invalid-feedback {
    display: block;
  }
}
</style>
